.dp__month_year_row {
  display: flex;
  align-items: center;
  height: $dp__month_year_row_height;
  color: var(--dp-text-color);
  box-sizing: border-box;
}

.dp__inner_nav {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: $dp__month_year_row_button_size;
  width: $dp__month_year_row_button_size;
  color: var(--dp-icon-color);
  text-align: center;
  border-radius: 50%;

  svg {
    height: $dp__button_icon_height;
    width: $dp__button_icon_height;
  }

  &:hover {
    background: var(--dp-hover-color);
    color: var(--dp-hover-icon-color);
  }
}

%__dp_inner_nav_disabled {
  background: var(--dp-disabled-color);
  color: var(--dp-disabled-color-text);
  cursor: not-allowed;
}

.dp__inner_nav_disabled {
  @extend %__dp_inner_nav_disabled;

  &:hover {
    @extend %__dp_inner_nav_disabled;
  }
}

.dp__month_year_select {
  width: 50%;
  text-align: center;
  cursor: pointer;
  height: $dp__month_year_row_height;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $dp__border_radius;
  box-sizing: border-box;

  &:hover {
    background: var(--dp-hover-color);
    color: var(--dp-hover-text-color);
  }
}

.dp__month_year_wrap {
  display: flex;
  width: 100%;
}
