*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.m-0 {
  margin: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-32 {
  margin-top: 8rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-1 {
  height: .25rem;
}

.h-28 {
  height: 7rem;
}

.h-3 {
  height: .75rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.w-3 {
  width: .75rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-full {
  width: 100%;
}

.max-w-\[150px\] {
  max-width: 150px;
}

.max-w-\[500px\] {
  max-width: 500px;
}

.max-w-full {
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.overflow-auto {
  overflow: auto;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-x-4 {
  border-left-width: 4px;
  border-right-width: 4px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-t {
  border-top-width: 1px;
}

.border-grigio {
  --tw-border-opacity: 1;
  border-color: rgb(239 239 239 / var(--tw-border-opacity));
}

.border-grigio-medio {
  --tw-border-opacity: 1;
  border-color: rgb(201 201 201 / var(--tw-border-opacity));
}

.bg-grigio {
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
}

.bg-primary-color {
  --tw-bg-opacity: 1;
  background-color: rgb(54 107 141 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.fill-current {
  fill: currentColor;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-3 {
  padding: .75rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-52 {
  padding-bottom: 13rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-7 {
  line-height: 1.75rem;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-grigio {
  --tw-text-opacity: 1;
  color: rgb(239 239 239 / var(--tw-text-opacity));
}

.text-grigio-scuro {
  --tw-text-opacity: 1;
  color: rgb(111 111 111 / var(--tw-text-opacity));
}

.text-primary-color {
  --tw-text-opacity: 1;
  color: rgb(54 107 141 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.outline {
  outline-style: solid;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.focus-within\:border-primary-color:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(54 107 141 / var(--tw-border-opacity));
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

@media (width >= 489px) {
  .min-\[489px\]\:mr-5 {
    margin-right: 1.25rem;
  }

  .min-\[489px\]\:inline-block {
    display: inline-block;
  }
}

@media (width >= 768px) {
  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:ml-3 {
    margin-left: .75rem;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:mr-3 {
    margin-right: .75rem;
  }

  .md\:block {
    display: block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

button.hmenu {
  border: 2px solid var(--button-color, #fff);
  background: none;
  border-radius: 10px;
}

.button-three {
  --button-color: white;
  overflow: hidden;
}

.button-three .hamburger {
  transition: translate 1s, rotate 1s;
}

.button-three[aria-expanded="true"] .hamburger {
  translate: 3.5px -3px;
  rotate: .125turn;
}

.button-three .line {
  stroke-dasharray: 60 31 60 300;
  transition: all .5s;
}

.button-three[aria-expanded="true"] .line {
  stroke-dasharray: 60 105 60 300;
  stroke-dashoffset: -90px;
}

@media screen and (width <= 767.98px) {
  #navbar-default {
    background: var(--grigio);
    width: auto;
    transition: all .5s;
    position: absolute;
    top: 130%;
    right: 0;
    overflow: hidden;
  }

  #navbar-default[aria-expanded="false"] {
    max-height: 0;
  }

  #navbar-default[aria-expanded="true"] {
    max-height: 300px;
  }

  #navbar-default ul {
    padding: 30px;
  }

  #navbar-default ul li a {
    white-space: nowrap;
    text-align: right;
    color: var(--grigio-scuro);
    display: block;
  }
}

header[data-v-ef9a94] {
  background-image: url("bg_header_2.f85a978f.png");
  background-position: 50% 65%;
  background-repeat: no-repeat;
  background-size: cover;
  width: min(100%, 1500px);
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 6px 6px -6px #0005;
}

.header-container[data-v-ef9a94] {
  z-index: 2;
  place-content: center space-between;
  place-items: baseline center;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

header > .container[data-v-ef9a94]:after {
  clip-path: inset(0 -100vmax);
  z-index: 1;
  background-color: #00000057;
  height: 100%;
  position: absolute;
  top: 0;
  box-shadow: 0 0 0 100vmax #00000057;
}

.logoTop[data-v-ef9a94] {
  max-width: 250px;
}

@media screen and (width <= 339.98px) {
  .logoTop[data-v-ef9a94] {
    max-width: 100%;
  }
}

@font-face {
  font-family: SF-UI-Thin;
  src: url("SF-UI-Display-Thin.d4cf4f30.woff");
}

@font-face {
  font-family: SF-UI;
  src: url("SF-UI-Display-Medium.fb3b431b.woff");
}

@font-face {
  font-family: SF-UI-Bold;
  src: url("SF-UI-Display-Bold.1ace360b.woff");
}

@font-face {
  font-family: NewYork;
  src: url("NewYorkMedium-Regular.b0811866.woff");
}

:root {
  --primary-color: #366b8d;
  --grigio: #efefef;
  --grigio-scuro: #6f6f6f;
  --grigio-medio: #c9c9c9;
  --links: var(--primary-color);
  --text-main: #000;
  --label: #6f6f6f;
  --background: transparent;
  --focus: var(--primary-color);
  --border: #c9c9c9;
  --max-width: 1020px;
  --scrollbar-thumb: var(--grigio) !important;
  --scrollbar-thumb-hover: #d9d9d9 !important;
}

@media (prefers-color-scheme: dark) {
  :root {
    --scrollbar-thumb: var(--grigio) !important;
    --scrollbar-thumb-hover: #d9d9d9 !important;
  }
}

html:not(.is-touch) ::-webkit-scrollbar-track {
  background-color: #0000 !important;
}

html:not(.is-touch) ::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  background-color: #0000 !important;
}

html:not(.is-touch) ::-webkit-scrollbar-thumb {
  background-color: var(--grigio-scuro) !important;
}

@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

*, :focus, :active, :hover {
  box-sizing: border-box;
  outline: none !important;
}

:focus + label:before {
  outline: none !important;
}

::placeholder {
  font-size: 12px;
}

#app {
  width: 100%;
}

body {
  font-family: SF-UI-Thin;
  max-width: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.font-bold, strong, b {
  font-family: SF-UI-Bold;
  font-weight: normal !important;
}

.sf-ui-bold {
  font-family: SF-UI-Bold;
}

.poppins {
  font-family: Poppins, sans-serif;
}

.monospace {
  font-family: monospace;
}

a.text-grigio:hover {
  text-shadow: 0 0 1px var(--grigio);
}

input {
  margin: 0;
}

input::placeholder, textarea::placeholder {
  opacity: .7;
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-style: italic;
  color: var(--grigio-scuro) !important;
}

input::-webkit-datetime-edit-fields-wrapper {
  opacity: .7;
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-style: italic;
  color: var(--grigio-scuro) !important;
}

input[type="text"], input[type="date"] {
  color: #000;
  background-color: var(--grigio);
  border: 0;
  border-radius: 50px;
  width: 100%;
  height: 36px;
  margin-top: 5px;
  padding: 0 15px;
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
}

.bg-grigio input[type="text"], input[type="date"] {
  background-color: #fff;
}

input:-webkit-autofill {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  background-color: var(--grigio) !important;
  box-shadow: 0 0 0 30px var(--grigio) inset !important;
  color: #000 !important;
  font-size: 16px !important;
}

input:-webkit-autofill:hover {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  background-color: var(--grigio) !important;
  box-shadow: 0 0 0 30px var(--grigio) inset !important;
  color: #000 !important;
  font-size: 16px !important;
}

input:-webkit-autofill:focus {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  background-color: var(--grigio) !important;
  box-shadow: 0 0 0 30px var(--grigio) inset !important;
  color: #000 !important;
  font-size: 16px !important;
}

textarea:-webkit-autofill {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  background-color: var(--grigio) !important;
  box-shadow: 0 0 0 30px var(--grigio) inset !important;
  color: #000 !important;
  font-size: 16px !important;
}

textarea:-webkit-autofill:hover {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  background-color: var(--grigio) !important;
  box-shadow: 0 0 0 30px var(--grigio) inset !important;
  color: #000 !important;
  font-size: 16px !important;
}

textarea:-webkit-autofill:focus {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  background-color: var(--grigio) !important;
  box-shadow: 0 0 0 30px var(--grigio) inset !important;
  color: #000 !important;
  font-size: 16px !important;
}

select:-webkit-autofill {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  background-color: var(--grigio) !important;
  box-shadow: 0 0 0 30px var(--grigio) inset !important;
  color: #000 !important;
  font-size: 16px !important;
}

select:-webkit-autofill:hover {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  background-color: var(--grigio) !important;
  box-shadow: 0 0 0 30px var(--grigio) inset !important;
  color: #000 !important;
  font-size: 16px !important;
}

select:-webkit-autofill:focus {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  background-color: var(--grigio) !important;
  box-shadow: 0 0 0 30px var(--grigio) inset !important;
  color: #000 !important;
  font-size: 16px !important;
}

input:-internal-autofill-selected {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  background-color: var(--grigio) !important;
  box-shadow: 0 0 0 30px var(--grigio) inset !important;
  color: #000 !important;
  font-size: 16px !important;
}

.bg-grigio input:-webkit-autofill {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  color: #000 !important;
  background-color: #fff !important;
  font-size: 16px !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

.bg-grigio input:-webkit-autofill:hover {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  color: #000 !important;
  background-color: #fff !important;
  font-size: 16px !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

.bg-grigio input:-webkit-autofill:focus {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  color: #000 !important;
  background-color: #fff !important;
  font-size: 16px !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

.bg-grigio textarea:-webkit-autofill {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  color: #000 !important;
  background-color: #fff !important;
  font-size: 16px !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

.bg-grigio textarea:-webkit-autofill:hover {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  color: #000 !important;
  background-color: #fff !important;
  font-size: 16px !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

.bg-grigio textarea:-webkit-autofill:focus {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  color: #000 !important;
  background-color: #fff !important;
  font-size: 16px !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

.bg-grigio select:-webkit-autofill {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  color: #000 !important;
  background-color: #fff !important;
  font-size: 16px !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

.bg-grigio select:-webkit-autofill:hover {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  color: #000 !important;
  background-color: #fff !important;
  font-size: 16px !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

.bg-grigio select:-webkit-autofill:focus {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  color: #000 !important;
  background-color: #fff !important;
  font-size: 16px !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

.bg-grigio input:-internal-autofill-selected {
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  color: #000 !important;
  background-color: #fff !important;
  font-size: 16px !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

input::-internal-input-suggested {
  font-size: 16px !important;
}

input[type="date" i]::-webkit-calendar-picker-indicator {
  width: 2em;
  height: 2em;
}

input:checked + div {
  --tw-border-opacity: 1;
  border-color: rgb(54 107 141 / var(--tw-border-opacity));
}

input:checked + div svg {
  display: block;
}

input + div + label {
  cursor: pointer;
}

input[type="submit"], input[type="button"], button[type="submit"], button[type="button"], .btn {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 50px;
  padding: 16px 72px;
  font-weight: bold;
  transition: all .5s;
  position: relative;
  box-shadow: -4px 4px 3px #00000015;
}

input[type="submit"]:is(:hover, :active), input[type="button"]:is(:hover, :active), button[type="submit"]:is(:hover, :active), button[type="button"]:is(:hover, :active), .btn:is(:hover, :active) {
  color: var(--primary-color);
}

input[type="submit"]:before, input[type="button"]:before, button[type="submit"]:before, button[type="button"]:before, .btn:before {
  content: "";
  z-index: 1;
  background-color: var(--primary-color);
  border-radius: 50px;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
}

input[type="submit"]:is(:hover, :active):before, input[type="button"]:is(:hover, :active):before, button[type="submit"]:is(:hover, :active):before, button[type="button"]:is(:hover, :active):before, .btn:is(:hover, :active):before {
  opacity: 0;
  transform: scale(.5);
}

input[type="submit"] span, input[type="button"] span, button[type="submit"] span, button[type="button"] span, .btn span {
  z-index: 2;
  position: relative;
}

input[type="submit"].small, input[type="button"].small, button.small, .btn.small, .inverse.small {
  padding: 5px 11px;
}

input[type="reset"], button[type="reset"], .inverse {
  color: var(--primary-color);
  text-transform: uppercase;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50px;
  padding: 16px 72px;
  font-weight: bold;
  transition: all .5s;
  position: relative;
  box-shadow: -4px 4px 3px #00000015;
}

input[type="reset"]:is(:hover, :active), button[type="reset"]:is(:hover, :active), .inverse:is(:hover, :active) {
  color: var(--primary-color);
}

input[type="reset"]:before, button[type="reset"]:before, .inverse:before {
  content: "";
  z-index: 1;
  background-color: var(--grigio);
  border-radius: 50px;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
}

input[type="reset"]:is(:hover, :active):before, button[type="reset"]:is(:hover, :active):before, .inverse:is(:hover, :active):before {
  opacity: 0;
  transform: scale(.5);
}

input[type="reset"] span, button[type="reset"] span, .inverse span {
  z-index: 2;
  position: relative;
}

[data-tooltip] {
  z-index: 2;
  cursor: pointer;
  position: relative;
}

[data-tooltip]:before, [data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

[data-tooltip]:before {
  background-color: var(--links);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  border-radius: 2px;
  width: 160px;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.2;
  position: absolute;
  bottom: 150%;
  left: 50%;
}

[data-tooltip]:after {
  border-top: 5px solid var(--links);
  content: " ";
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  width: 0;
  margin-left: -5px;
  font-size: 0;
  line-height: 0;
  position: absolute;
  bottom: 150%;
  left: 50%;
}

[data-tooltip]:hover:before, [data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

.scale-enter-active, .scale-leave-active {
  transition: all .5s;
}

.scale-enter-from, .scale-leave-to {
  opacity: 0;
  transform: scale(.9);
}

.dp__input_wrap {
  box-sizing: unset;
  width: 100%;
  position: relative;
}

.dp__input_wrap:focus {
  border-color: var(--dp-border-color-hover);
  outline: none;
}

.dp__input {
  background-color: var(--dp-background-color);
  border: 1px solid var(--dp-border-color);
  color: var(--dp-text-color);
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  width: 100%;
  padding: 6px 12px;
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
}

.dp__input::placeholder {
  opacity: .7;
}

.dp__input:hover {
  border-color: var(--dp-border-color-hover);
}

.dp__input_reg {
  caret-color: #0000;
}

.dp__input_focus {
  border-color: var(--dp-border-color-hover);
}

.dp__disabled {
  background: var(--dp-disabled-color);
}

.dp__disabled::placeholder {
  color: var(--dp-disabled-color-text);
}

.dp__input_icons {
  stroke-width: 0;
  color: var(--dp-icon-color);
  box-sizing: content-box;
  width: 1rem;
  height: 1rem;
  padding: 6px 12px;
  font-size: 1rem;
  line-height: 1.5rem;
  display: inline-block;
}

.dp__input_icon {
  cursor: pointer;
  color: var(--dp-icon-color);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.dp__clear_icon {
  cursor: pointer;
  color: var(--dp-icon-color);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.dp__input_icon_pad {
  padding-left: 35px;
}

.dp__input_valid {
  box-shadow: 0 0 4px var(--dp-success-color);
  border-color: var(--dp-success-color);
}

.dp__input_valid:hover {
  border-color: var(--dp-success-color);
}

.dp__input_invalid {
  box-shadow: 0 0 4px var(--dp-danger-color);
  border-color: var(--dp-danger-color);
}

.dp__input_invalid:hover {
  border-color: var(--dp-danger-color);
}

.dp__menu {
  background: var(--dp-background-color);
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid var(--dp-menu-border-color);
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 260px;
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 1rem;
  position: absolute;
}

.dp__menu:after, .dp__menu:before {
  box-sizing: border-box;
}

.dp__menu:focus {
  border: 1px solid var(--dp-menu-border-color);
  outline: none;
}

.dp__menu_index {
  z-index: 99999;
}

.dp__menu_readonly, .dp__menu_disabled {
  z-index: 1;
  position: absolute;
  inset: 0;
}

.dp__menu_disabled {
  cursor: not-allowed;
  background: #ffffff80;
}

.dp__menu_readonly {
  cursor: default;
  background: none;
}

.dp__arrow_top {
  background-color: var(--dp-background-color);
  border-left: 1px solid var(--dp-menu-border-color);
  border-top: 1px solid var(--dp-menu-border-color);
  width: 12px;
  height: 12px;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.dp__arrow_bottom {
  background-color: var(--dp-background-color);
  border-right: 1px solid var(--dp-menu-border-color);
  border-bottom: 1px solid var(--dp-menu-border-color);
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translate(-50%, 50%)rotate(45deg);
}

.dp__now_wrap {
  text-align: center;
  padding: 2px 0;
}

.dp__now_button {
  border: 1px solid var(--dp-primary-color);
  color: var(--dp-primary-color);
  cursor: pointer;
  background: none;
  border-radius: 4px;
  padding: 0 4px;
  font-size: 1rem;
  font-weight: bold;
}

.dp__preset_ranges, .dp__sidebar_left {
  border-right: 1px solid var(--dp-border-color);
  padding: 5px;
}

.dp__sidebar_right {
  border-left: 1px solid var(--dp-border-color);
  padding: 5px;
}

.dp__preset_range {
  padding: 5px;
}

.dp__preset_range:hover {
  background-color: var(--dp-hover-color);
  cursor: pointer;
}

.dp__menu_content_wrapper {
  display: flex;
}

.dp__calendar_wrap {
  flex-direction: column;
  flex: 0;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  display: flex;
}

.dp__calendar_header {
  color: var(--dp-text-color);
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  display: flex;
  position: relative;
}

.dp__calendar_header_item {
  text-align: center;
  box-sizing: border-box;
  flex-grow: 1;
  width: 35px;
  height: 35px;
  padding: 5px;
}

.dp__calendar_row {
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  display: flex;
}

.dp__calendar_item {
  text-align: center;
  box-sizing: border-box;
  color: var(--dp-text-color);
  flex-grow: 1;
}

.dp__calendar {
  position: relative;
}

.dp__calendar_header_cell {
  border-bottom: thin solid var(--dp-border-color);
  padding: .5rem;
}

.dp__cell_inner {
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #0000;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  padding: 5px;
  display: flex;
  position: relative;
}

.dp__cell_auto_range_start, .dp__date_hover_start:hover, .dp__range_start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dp__cell_auto_range_end, .dp__date_hover_end:hover, .dp__range_end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dp__range_end, .dp__range_start, .dp__active_date {
  background: var(--dp-primary-color);
  color: var(--dp-primary-text-color);
}

.dp__cell_auto_range_end, .dp__cell_auto_range_start {
  border-top: 1px dashed var(--dp-primary-color);
  border-bottom: 1px dashed var(--dp-primary-color);
}

.dp__date_hover_end:hover, .dp__date_hover_start:hover, .dp__date_hover:hover {
  background: var(--dp-hover-color);
  color: var(--dp-hover-text-color);
}

.dp__cell_offset {
  color: var(--dp-secondary-color);
}

.dp__cell_disabled {
  color: var(--dp-secondary-color);
  cursor: not-allowed;
}

.dp__range_between {
  background: var(--dp-hover-color);
  border-top: 1px solid var(--dp-hover-color);
  border-bottom: 1px solid var(--dp-hover-color);
  border-radius: 0;
}

.dp__range_between_week {
  background: var(--dp-primary-color);
  color: var(--dp-primary-text-color);
  border-top: 1px solid var(--dp-primary-color);
  border-bottom: 1px solid var(--dp-primary-color);
  border-radius: 0;
}

.dp__today {
  border: 1px solid var(--dp-primary-color);
}

.dp__week_num {
  color: var(--dp-secondary-color);
  text-align: center;
}

.dp__cell_auto_range {
  border-top: 1px dashed var(--dp-primary-color);
  border-bottom: 1px dashed var(--dp-primary-color);
  border-radius: 0;
}

.dp__cell_auto_range_start {
  border-left: 1px dashed var(--dp-primary-color);
}

.dp__cell_auto_range_end {
  border-right: 1px dashed var(--dp-primary-color);
}

.dp__calendar_header_separator {
  background: var(--dp-border-color);
  width: 100%;
  height: 1px;
}

.dp__calendar_next {
  margin-left: 10px;
}

.dp__marker_line, .dp__marker_dot {
  background-color: var(--dp-marker-color);
  height: 5px;
  position: absolute;
  bottom: 0;
}

.dp__marker_dot {
  border-radius: 50%;
  width: 5px;
  left: 50%;
  transform: translateX(-50%);
}

.dp__marker_line {
  width: 100%;
  left: 0;
}

.dp__marker_tooltip {
  background-color: var(--dp-tooltip-color);
  border: 1px solid var(--dp-border-color);
  z-index: 99999;
  box-sizing: border-box;
  cursor: default;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
}

.dp__tooltip_content {
  white-space: nowrap;
}

.dp__tooltip_text {
  color: var(--dp-text-color);
  flex-flow: row;
  align-items: center;
  display: flex;
}

.dp__tooltip_mark {
  background-color: var(--dp-text-color);
  color: var(--dp-text-color);
  border-radius: 50%;
  width: 5px;
  height: 5px;
  margin-right: 5px;
}

.dp__arrow_bottom_tp {
  background-color: var(--dp-tooltip-color);
  border-right: 1px solid var(--dp-border-color);
  border-bottom: 1px solid var(--dp-border-color);
  width: 8px;
  height: 8px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%)rotate(45deg);
}

.dp__instance_calendar {
  width: 100%;
  position: relative;
}

@media only screen and (width <= 600px) {
  .dp__flex_display {
    flex-direction: column;
  }
}

.dp__cell_highlight {
  background-color: var(--dp-highlight-color);
}

.dp__month_year_row {
  color: var(--dp-text-color);
  box-sizing: border-box;
  align-items: center;
  height: 35px;
  display: flex;
}

.dp__inner_nav {
  cursor: pointer;
  color: var(--dp-icon-color);
  text-align: center;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  display: flex;
}

.dp__inner_nav svg {
  width: 20px;
  height: 20px;
}

.dp__inner_nav:hover {
  background: var(--dp-hover-color);
  color: var(--dp-hover-icon-color);
}

.dp__inner_nav_disabled:hover, .dp__inner_nav_disabled {
  background: var(--dp-disabled-color);
  color: var(--dp-disabled-color-text);
  cursor: not-allowed;
}

.dp__month_year_select {
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 35px;
  display: flex;
}

.dp__month_year_select:hover {
  background: var(--dp-hover-color);
  color: var(--dp-hover-text-color);
}

.dp__month_year_wrap {
  width: 100%;
  display: flex;
}

.dp__overlay {
  background: var(--dp-background-color);
  z-index: 99999;
  color: var(--dp-text-color);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  transition: opacity 1s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.dp__overlay_container::-webkit-scrollbar-track {
  box-shadow: var(--dp-scroll-bar-background);
  background-color: var(--dp-scroll-bar-background);
}

.dp__overlay_container::-webkit-scrollbar {
  background-color: var(--dp-scroll-bar-background);
  width: 5px;
}

.dp__overlay_container::-webkit-scrollbar-thumb {
  background-color: var(--dp-scroll-bar-color);
  border-radius: 10px;
}

.dp__overlay:focus {
  border: none;
  outline: none;
}

.dp__container_flex {
  display: flex;
}

.dp__container_block {
  display: block;
}

.dp__overlay_container {
  flex-direction: column;
  overflow-y: auto;
}

.dp__time_picker_overlay_container {
  height: 100%;
}

.dp__overlay_row {
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  display: flex;
}

.dp__overlay_container > .dp__overlay_row {
  flex: 1;
}

.dp__overlay_col {
  box-sizing: border-box;
  white-space: nowrap;
  width: 33%;
  padding: 3px;
}

.dp__overlay_cell_pad {
  padding: 10px 0;
}

.dp__overlay_cell_active {
  cursor: pointer;
  text-align: center;
  background: var(--dp-primary-color);
  color: var(--dp-primary-text-color);
  border-radius: 4px;
}

.dp__overlay_cell {
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
}

.dp__overlay_cell:hover, .dp__cell_in_between {
  background: var(--dp-hover-color);
  color: var(--dp-hover-text-color);
}

.dp__overlay_action {
  background: #fff;
  position: sticky;
  bottom: 0;
}

.dp__over_action_scroll {
  box-sizing: border-box;
  right: 5px;
}

.dp__overlay_cell_disabled {
  cursor: not-allowed;
  background: var(--dp-disabled-color);
}

.dp__overlay_cell_disabled:hover {
  background: var(--dp-disabled-color);
}

.dp__overlay_cell_active_disabled {
  cursor: not-allowed;
  background: var(--dp-primary-disabled-color);
}

.dp__overlay_cell_active_disabled:hover {
  background: var(--dp-primary-disabled-color);
}

.dp__month_picker_header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 35px;
  display: flex;
}

.dp__time_input {
  -webkit-user-select: none;
  user-select: none;
  color: var(--dp-text-color);
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
  display: flex;
}

.dp__time_col_reg {
  padding: 0 20px;
}

.dp__time_col_reg_with_button {
  padding: 0 15px;
}

.dp__time_col_sec {
  padding: 0 10px;
}

.dp__time_col_sec_with_button {
  padding: 0 5px;
}

.dp__time_col {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  display: flex;
}

.dp__time_display {
  cursor: pointer;
  color: var(--dp-text-color);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 0 3px;
  display: flex;
}

.dp__time_display:hover {
  background: var(--dp-hover-color);
  color: var(--dp-hover-text-color);
}

.dp__inc_dec_button {
  cursor: pointer;
  color: var(--dp-icon-color);
  box-sizing: border-box;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 5px;
  display: flex;
}

.dp__inc_dec_button svg {
  width: 32px;
  height: 32px;
}

.dp__inc_dec_button:hover {
  background: var(--dp-hover-color);
  color: var(--dp-primary-color);
}

.dp__inc_dec_button_disabled:hover, .dp__inc_dec_button_disabled {
  background: var(--dp-disabled-color);
  color: var(--dp-disabled-color-text);
  cursor: not-allowed;
}

.dp__pm_am_button {
  background: var(--dp-primary-color);
  color: var(--dp-primary-text-color);
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 10px;
}

.dp__action_row {
  box-sizing: border-box;
  color: var(--dp-text-color);
  background: var(--dp-background-color);
  align-items: center;
  width: 100%;
  padding: 10px;
  display: flex;
}

.dp__action_row svg {
  width: auto;
  height: 20px;
}

.dp__selection_preview {
  color: var(--dp-text-color);
  width: 50%;
  font-size: .8rem;
}

.dp__action_buttons {
  text-align: right;
  width: 50%;
}

.dp__action {
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  padding: 2px 5px;
  font-weight: bold;
  display: inline-flex;
}

.dp__select {
  color: var(--dp-success-color);
}

.dp__action_disabled {
  color: var(--dp-success-color-disabled);
  cursor: not-allowed;
}

.dp__cancel {
  color: var(--dp-secondary-color);
}

.dp__theme_dark {
  --dp-background-color: #212121;
  --dp-text-color: #fff;
  --dp-hover-color: #484848;
  --dp-hover-text-color: #fff;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #005cb2;
  --dp-primary-disabled-color: #61a8ea;
  --dp-primary-text-color: #fff;
  --dp-secondary-color: #a9a9a9;
  --dp-border-color: #2d2d2d;
  --dp-menu-border-color: #2d2d2d;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #737373;
  --dp-disabled-color-text: #d0d0d0;
  --dp-scroll-bar-background: #212121;
  --dp-scroll-bar-color: #484848;
  --dp-success-color: #00701a;
  --dp-success-color-disabled: #428f59;
  --dp-icon-color: #959595;
  --dp-danger-color: #e53935;
  --dp-marker-color: #e53935;
  --dp-tooltip-color: #3e3e3e;
  --dp-highlight-color: #005cb233;
}

.dp__theme_light {
  --dp-background-color: #fff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #1976d2;
  --dp-primary-disabled-color: #6bacea;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-marker-color: #ff6f60;
  --dp-tooltip-color: #fafafa;
  --dp-disabled-color-text: #8e8e8e;
  --dp-highlight-color: #1976d21a;
}

.dp__main {
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, Open Sans, Helvetica Neue, sans-serif;
}

.dp__pointer {
  cursor: pointer;
}

.dp__icon {
  stroke: currentColor;
  fill: currentColor;
}

.dp__button {
  text-align: center;
  color: var(--dp-icon-color);
  background: var(--dp-background-color);
  cursor: pointer;
  box-sizing: border-box;
  place-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 10px;
  display: flex;
}

.dp__button:hover {
  background: var(--dp-hover-color);
  color: var(--dp-hover-icon-color);
}

.dp__button svg {
  width: auto;
  height: 20px;
}

.dp__button_bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.dp__flex_display {
  display: flex;
}

.dp__flex_display_with_input {
  flex-direction: column;
  align-items: start;
}

.dp__relative {
  position: relative;
}

.calendar-next-enter-active, .calendar-next-leave-active, .calendar-prev-enter-active, .calendar-prev-leave-active {
  transition: all .1s ease-out;
}

.calendar-next-enter-from {
  opacity: 0;
  transform: translateX(22px);
}

.calendar-next-leave-to, .calendar-prev-enter-from {
  opacity: 0;
  transform: translateX(-22px);
}

.calendar-prev-leave-to {
  opacity: 0;
  transform: translateX(22px);
}

.dp-menu-appear-enter-active, .dp-menu-appear-leave-active, .dp-slide-up-enter-active, .dp-slide-up-leave-active, .dp-slide-down-enter-active, .dp-slide-down-leave-active {
  transition: all .1s ease-out;
}

.dp-slide-down-leave-to, .dp-slide-up-enter-from {
  opacity: 0;
  transform: translateY(22px);
}

.dp-slide-down-enter-from, .dp-slide-up-leave-to {
  opacity: 0;
  transform: translateY(-22px);
}

.dp-menu-appear-enter-from {
  opacity: 0;
}

.dp-menu-appear-enter-active, .dp-menu-appear-leave-active {
  transition: opacity .1s;
}

.dp-menu-appear-leave-to {
  opacity: 1;
}

.dp__theme_light {
  --primary-color: #366b8d;
  --grigio: #efefef;
  --grigio-scuro: #6f6f6f;
  --grigio-medio: #c9c9c9;
  --text-main: #000;
  --dp-text-color: var(--text-main);
  --dp-hover-color: var(--grigio-medio);
  --dp-hover-text-color: var(--text-main);
  --dp-primary-color: var(--primary-color);
  --dp-border-color: var(--grigio-medio);
  --dp-menu-border-color: var(--grigio-medio);
  --dp-border-color-hover: var(--grigio-medio);
  --dp-disabled-color-text: var(--grigio-medio);
}

.dp__menu {
  border-radius: 20px;
}

.dp__input {
  color: var(--primary-color);
  background-color: var(--grigio);
  border: 0;
  border-radius: 50px;
  width: 100%;
  height: 36px;
  margin-top: 5px;
  font-size: 16px;
}

.bg-grigio .dp__input {
  background-color: #fff;
}

.search-results {
  box-shadow: 0 0 0 100vmax var(--grigio);
  clip-path: inset(0 -100vmax);
}

.search-results ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.search-results ul li:hover {
  animation: .4s cubic-bezier(.25, .46, .45, .94) both shadow-drop-center;
}

.links {
  background: var(--primary-color);
  text-align: center;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  display: inline-block;
}

.links:hover {
  color: var(--primary-color);
  background: var(--grigio);
  text-decoration: none;
}

.slide-in-fwd-center {
  animation: .4s cubic-bezier(.25, .46, .45, .94) both slide-in-fwd-center;
}

@keyframes slide-in-fwd-center {
  0% {
    opacity: 0;
    transform: translateZ(-1400px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes shadow-drop-center {
  0% {
    box-shadow: 0 0 #0000;
  }

  100% {
    box-shadow: 0 0 20px #00000059;
  }
}

/*# sourceMappingURL=index.fe8aafd2.css.map */
