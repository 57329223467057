
header[data-v-ef9a94] {
    width: min(100%, 1500px);
    background-image: url(../../public/bg_header_2.png);
    background-position: center 65%;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 6px 6px -6px #0005;
    background-size: cover;
}
.header-container[data-v-ef9a94] {
    justify-content: space-between;
    margin: 0 auto;
    display: flex;
    align-content: center;
    justify-items: center;
    align-items: baseline;
    width: 100%;
    z-index: 2;
    /* background-color: #00000057; */
}
header > .container[data-v-ef9a94]:after {
    position: absolute;
    top: 0;
    height: 100%;
    /* content: ''; */
    box-shadow: 0 0 0 100vmax #00000057;
    clip-path: inset(0 -100vmax);
    background-color: #00000057;
    z-index: 1;
}
.logoTop[data-v-ef9a94] {
    max-width: 250px;
}
@media screen and (max-width: 339.98px) {
.logoTop[data-v-ef9a94] {
      max-width: 100%;
}
}
