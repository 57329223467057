
button.hmenu {
    background: transparent;
    border: 2px solid var(--button-color, #fff);
    border-radius: 10px;
}
.button-three {
    --button-color: white;
    overflow: hidden;
}
.button-three .hamburger {
    transition: translate 1s, rotate 1s;
}
.button-three[aria-expanded='true'] .hamburger {
    translate: 3.5px -3px;
    rotate: 0.125turn;
}
.button-three .line {
    transition: 0.5s;
    stroke-dasharray: 60 31 60 300;
}
.button-three[aria-expanded='true'] .line {
    stroke-dasharray: 60 105 60 300;
    stroke-dashoffset: -90;
}
@media screen and (max-width: 767.98px) {
#navbar-default {
      position: absolute;
      top: 130%;
      background: var(--grigio);
      width: auto;
      right: 0;
      overflow: hidden;
      transition: 0.5s;
}
#navbar-default[aria-expanded='false'] {
      max-height: 0;
}
#navbar-default[aria-expanded='true'] {
      max-height: 300px;
}
#navbar-default ul {
      padding: 30px;
}
#navbar-default ul li a {
      white-space: nowrap;
      display: block;
      text-align: right;
      color: var(--grigio-scuro);
}
}
