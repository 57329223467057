
.dp__theme_light {
    --primary-color: #366b8d;
    --grigio: #efefef;
    --grigio-scuro: #6f6f6f;
    --grigio-medio: #c9c9c9;
    --text-main: #000;
    --dp-text-color: var(--text-main);
    --dp-hover-color: var(--grigio-medio);
    --dp-hover-text-color: var(--text-main);
    --dp-primary-color: var(--primary-color);
    --dp-border-color: var(--grigio-medio);
    --dp-menu-border-color: var(--grigio-medio);
    --dp-border-color-hover: var(--grigio-medio);
    --dp-disabled-color-text: var(--grigio-medio);
}
.dp__menu {
    border-radius: 20px;
}
.dp__input {
    /* border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--grigio-medio);
    height: 36px;
    color: var(--primary-color);
    width: 100%;
    background-color: #0000;
    margin-top: 5px;
    font-size: 16px; */
    height: 36px;
    font-size: 16px;
    margin-top: 5px;
    color: var(--primary-color);
    width: 100%;

    border: 0;
    background-color: var(--grigio);
    /* padding: 0px 15px; */
    border-radius: 50px;
}
.bg-grigio .dp__input {
    background-color: #ffffff;
}
.search-results {
    box-shadow: 0 0 0 100vmax var(--grigio);
    clip-path: inset(0 -100vmax);
}
.search-results ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.search-results ul li:hover {
    animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.links {
    background: var(--primary-color);
    height: 30px;
    width: 30px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    margin-left: 10px;
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    display: inline-block;
}
.links:hover {
    text-decoration: none;
    color: var(--primary-color);
    background: var(--grigio);
}
.slide-in-fwd-center {
    animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes slide-in-fwd-center {
0% {
      transform: translateZ(-1400px);
      opacity: 0;
}
100% {
      transform: translateZ(0);
      opacity: 1;
}
}
@keyframes shadow-drop-center {
0% {
      box-shadow: 0 0 0 0 transparent;
}
100% {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
}
}
