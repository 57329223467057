.dp__action_row {
  display: flex;
  align-items: center;
  width: 100%;
  padding: $dp__common_padding;
  box-sizing: border-box;
  color: var(--dp-text-color);
  background: var(--dp-background-color);

  svg {
    height: $dp__button_icon_height;
    width: auto;
  }
}

.dp__selection_preview {
  width: 50%;
  color: var(--dp-text-color);
  font-size: $dp__preview_font_size;
}

.dp__action_buttons {
  width: 50%;
  text-align: right;
}

.dp__action {
  font-weight: bold;
  cursor: pointer;
  padding: $dp__action_buttons_padding;
  border-radius: $dp__border_radius;
  display: inline-flex;
  align-items: center;
}

.dp__select {
  color: var(--dp-success-color);
}

.dp__action_disabled {
  color: var(--dp-success-color-disabled);
  cursor: not-allowed;
}

.dp__cancel {
  color: var(--dp-secondary-color);
}
