.dp__menu {
  position: absolute;
  background: var(--dp-background-color);
  border-radius: $dp__border_radius;
  min-width: $dp__menu_min_width;
  font-family: $dp__font_family;
  font-size: $dp__font_size;
  user-select: none;
  border: 1px solid var(--dp-menu-border-color);
  box-sizing: border-box;

  &::after {
    box-sizing: border-box;
  }

  &::before {
    box-sizing: border-box;
  }

  &:focus {
    border: 1px solid var(--dp-menu-border-color);
    outline: none;
  }
}

.dp__menu_index {
  z-index: 99999;
}

%__dp_menu_readonly_disabled {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.dp__menu_disabled {
  @extend %__dp_menu_readonly_disabled;

  background: rgb(255 255 255 / 50%);
  cursor: not-allowed;
}

.dp__menu_readonly {
  @extend %__dp_menu_readonly_disabled;

  background: transparent;
  cursor: default;
}

.dp__arrow_top {
  left: 50%;
  top: -1px;
  height: 12px;
  width: 12px;
  background-color: var(--dp-background-color);
  position: absolute;
  border-left: 1px solid var(--dp-menu-border-color);
  border-top: 1px solid var(--dp-menu-border-color);
  transform: translate(-50%, -50%) rotate(45deg);
}

.dp__arrow_bottom {
  left: 50%;
  bottom: -1px;
  height: 12px;
  width: 12px;
  background-color: var(--dp-background-color);
  position: absolute;
  border-right: 1px solid var(--dp-menu-border-color);
  border-bottom: 1px solid var(--dp-menu-border-color);
  transform: translate(-50%, 50%) rotate(45deg);
}

.dp__now_wrap {
  text-align: center;
  padding: 2px 0;
}

.dp__now_button {
  border: 1px solid var(--dp-primary-color);
  color: var(--dp-primary-color);
  padding: 0 4px;
  font-weight: bold;
  border-radius: $dp__border_radius;
  font-size: $dp__font_size;
  cursor: pointer;
  background: transparent;
}

.dp__preset_ranges {
  padding: 5px;
  border-right: 1px solid var(--dp-border-color);
}

.dp__sidebar_left {
  padding: 5px;
  border-right: 1px solid var(--dp-border-color);
}

.dp__sidebar_right {
  padding: 5px;
  border-left: 1px solid var(--dp-border-color);
}

.dp__preset_range {
  padding: 5px;

  &:hover {
    background-color: var(--dp-hover-color);
    cursor: pointer;
  }
}

.dp__menu_content_wrapper {
  display: flex;
}
