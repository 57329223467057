.dp__calendar_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: $dp__font_family;
  flex: 0;
}

.dp__calendar_header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dp-text-color);
  white-space: nowrap;
  font-weight: bold;
}

.dp__calendar_header_item {
  text-align: center;
  flex-grow: 1;
  height: $dp__cell_size;
  padding: $dp__cell_padding;
  width: $dp__cell_size;
  box-sizing: border-box;
}

.dp__calendar_row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: $dp__row_margin;
}

.dp__calendar_item {
  text-align: center;
  flex-grow: 1;
  box-sizing: border-box;
  color: var(--dp-text-color);
}

.dp__calendar {
  position: relative;
}

.dp__calendar_header_cell {
  border-bottom: thin solid var(--dp-border-color);
  padding: $dp__calendar_header_cell_padding;
}

.dp__cell_inner {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: $dp__cell_border_radius;
  height: $dp__cell_size;
  padding: $dp__cell_padding;
  width: $dp__cell_size;
  border: 1px solid transparent;
  box-sizing: border-box;
  position: relative;
}

%__dp_range_border_radius_start {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

%__dp_range_border_radius_end {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

%__dp_active {
  background: var(--dp-primary-color);
  color: var(--dp-primary-text-color);
}

%__dp_range_preview {
  border-top: 1px dashed var(--dp-primary-color);
  border-bottom: 1px dashed var(--dp-primary-color);
}

%__hover_date {
  background: var(--dp-hover-color);
  color: var(--dp-hover-text-color);
}

.dp__cell_offset {
  color: var(--dp-secondary-color);
}

.dp__cell_disabled {
  color: var(--dp-secondary-color);
  cursor: not-allowed;
}

.dp__active_date {
  @extend %__dp_active;
}

.dp__range_start {
  @extend %__dp_active;
  @extend %__dp_range_border_radius_start;
}

.dp__range_end {
  @extend %__dp_active;
  @extend %__dp_range_border_radius_end;
}

.dp__date_hover {
  &:hover {
    @extend %__hover_date;
  }
}

.dp__date_hover_start {
  &:hover {
    @extend %__dp_range_border_radius_start;
    @extend %__hover_date;
  }
}

.dp__date_hover_end {
  &:hover {
    @extend %__dp_range_border_radius_end;
    @extend %__hover_date;
  }
}

.dp__range_between {
  background: var(--dp-hover-color);
  border-radius: 0;
  border-top: 1px solid var(--dp-hover-color);
  border-bottom: 1px solid var(--dp-hover-color);
}

.dp__range_between_week {
  background: var(--dp-primary-color);
  color: var(--dp-primary-text-color);
  border-radius: 0;
  border-top: 1px solid var(--dp-primary-color);
  border-bottom: 1px solid var(--dp-primary-color);
}

.dp__today {
  border: 1px solid var(--dp-primary-color);
}

.dp__week_num {
  color: var(--dp-secondary-color);
  text-align: center;
}

.dp__cell_auto_range {
  border-radius: 0;
  border-top: 1px dashed var(--dp-primary-color);
  border-bottom: 1px dashed var(--dp-primary-color);
}

.dp__cell_auto_range_start {
  @extend %__dp_range_border_radius_start;
  @extend %__dp_range_preview;

  border-left: 1px dashed var(--dp-primary-color);
}

.dp__cell_auto_range_end {
  @extend %__dp_range_border_radius_end;
  @extend %__dp_range_preview;

  border-right: 1px dashed var(--dp-primary-color);
}

.dp__calendar_header_separator {
  width: 100%;
  height: 1px;
  background: var(--dp-border-color);
}

.dp__calendar_next {
  margin-left: $dp__two_calendars_spacing;
}

%__dp_marker {
  height: 5px;
  background-color: var(--dp-marker-color);
  position: absolute;
  bottom: 0;
}

.dp__marker_dot {
  width: 5px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);

  @extend %__dp_marker;
}

.dp__marker_line {
  width: 100%;
  left: 0;

  @extend %__dp_marker;
}

.dp__marker_tooltip {
  position: absolute;
  border-radius: $dp__border_radius;
  background-color: var(--dp-tooltip-color);
  padding: 5px;
  border: 1px solid var(--dp-border-color);
  z-index: 99999;
  box-sizing: border-box;
  cursor: default;
}

.dp__tooltip_content {
  white-space: nowrap;
}

.dp__tooltip_text {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  color: var(--dp-text-color);
}

.dp__tooltip_mark {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: var(--dp-text-color);
  color: var(--dp-text-color);
  margin-right: 5px;
}

.dp__arrow_bottom_tp {
  left: 50%;
  bottom: 0;
  height: 8px;
  width: 8px;
  background-color: var(--dp-tooltip-color);
  position: absolute;
  border-right: 1px solid var(--dp-border-color);
  border-bottom: 1px solid var(--dp-border-color);
  transform: translate(-50%, 50%) rotate(45deg);
}

.dp__instance_calendar {
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .dp__flex_display {
    flex-direction: column;
  }
}

.dp__cell_highlight {
  background-color: var(--dp-highlight-color);
}
